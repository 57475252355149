<template>
  <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Create a new Tenant</md-dialog-title>

    <md-dialog-content class="md-scrollbar">
      <ValidationObserver ref="tenantForm">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <div class="md-layout-item md-size-100">
              <h6><strong>Tenant Information</strong></h6>


              <ValidationProvider rules="required" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Name</label>
                  <md-input v-model="tenant.name" maxlength="50"></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>

              <ValidationProvider :rules="{ required: true, regex: /^[a-z-_]+$/ }" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Tenant Code</label>
                  <md-input v-model="tenant.tenantCode" v-lowercase type="text"
                            maxlength="13" :disabled="isEditMode"></md-input>
                  <span class="md-helper-text">
                    eg.&nbsp;
                    <code>customer-name</code>
                    &nbsp;or&nbsp;
                    <code>customer-name-countrycode</code>
                    .
                  </span>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>




              <ValidationProvider :rules="{ required: true, regex: /^[a-z-_]+$/ }" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <md-tooltip md-direction="top" style="max-width: 800px; text-align: left">
                    <div>

                      This value should be a logical name for a group of tenants that belong to the customer,
                      <br/>
                      where you can easily identify which tenant codes belong to this group.
                      <hr/>
                      E.g. tenant group <code>echo</code> would belong to tenant codes <code>echo-claims</code> and <code>echo-uw</code>.
                    </div>

                  </md-tooltip>
                  <label>Tenant Group</label>
                  <md-input v-model="tenant.tenantGroup" v-lowercase type="text" maxlength="250" :disabled="isEditMode"></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>

              <ValidationProvider rules="required" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Domain</label>
                  <md-select name="domain" id="domain" v-model="tenant.domain">
                    <md-option v-for="item in domains" :key="item.id.toLowerCase()" :value="item.id.toLowerCase()">
                      {{ item.description }}
                    </md-option>
                  </md-select>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>

              <ValidationProvider rules="required" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Product</label>
                  <md-select name="product" id="product" v-model="tenant.product">
                    <md-option v-for="product in products" :key="product.id" :value="product.id">
                      {{ product.description }}
                    </md-option>
                  </md-select>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout">
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Country</label>
                    <md-select name="country" id="country" v-model="tenant.country">
                      <md-option v-for="country in countries" :key="country.value.toLowerCase()" :value="country.value.toLowerCase()">
                        {{ country.text }}
                      </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Language</label>
                    <md-select name="language" id="language" v-model="tenant.language">
                      <md-option v-for="language in languages" :key="language.value.toLowerCase()" :value="language.value.toLowerCase()">
                        {{ language.text }}
                      </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Currency</label>
                    <md-select name="currency" id="currency" v-model="tenant.currency">
                      <md-option v-for="currency in currencies" :key="currency.value.toLowerCase()" :value="currency.value.toLowerCase()">
                        {{ currency.text }}
                      </md-option>
                    </md-select>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>

            <div class="md-layout-item md-size-100">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Deployment Environment</label>
                  <md-select name="environment" id="environment" v-model="tenant.deploymentEnvironment">
                    <md-option v-for="item in availableRegions" :key="item.regionName" :value="item.regionName">
                      {{ item.regionName }}
                    </md-option>
                  </md-select>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-size-100">
              <md-switch v-model="tenant.cloudConsent" :disabled="!canChangeCloudConsent">
                Cloud Consent
                <md-tooltip v-if="canChangeCloudConsent">
                  Customer has agreed to have
                  <br />
                  data processed and stored
                  <br />
                  in the cloud.
                </md-tooltip>
                <md-tooltip v-else>
                  Customer has already been
                  <br />
                  provisioned to the cloud.
                  <br />
                  Therefore it cannot be changed.
                </md-tooltip>
              </md-switch>
              <md-switch v-model="tenant.fscCloud" :disabled="!tenant.cloudConsent">
                FSC Cloud Tenant
                <md-tooltip>
                  Customer <b>is ready to use
                  <br />
                  Cloud</b> version of
                  <br />
                  FRISS Screening Center
                </md-tooltip>
              </md-switch>
            </div>
          </div>

          <div class="md-layout-item md-size-50">
            <div class="md-layout-item md-size-100">
              <h6><strong>First User Information</strong></h6>
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <md-field :class="{ 'md-invalid': errors.length > 0 }">
                  <label>Email Address</label>
                  <md-input v-model="tenant.firstUser.emailAddress"></md-input>
                  <span class="md-error">{{ errors[0] }}</span>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>First Name</label>
                    <md-input v-model="tenant.firstUser.firstName"></md-input>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Last Name</label>
                    <md-input v-model="tenant.firstUser.lastName"></md-input>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <div class="md-layout">
                <div class="md-size-50">
                  <h6>
                    <strong>Additional Administrators</strong>
                  </h6>
                </div>
                <div class="md-layout-item md-size-10">
                  <md-button class="md-just-icon md-simple md-primary" @click="addAdmin()" style="height: 15px">
                    <md-icon>add</md-icon>
                    <md-tooltip md-direction="top">
                      Add Another Administrator
                    </md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>

            <div class="md-layout" v-for="(admin, index) in tenant.additionalAdmins" :key="index">
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <md-field class="outer" :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Email Address</label>
                    <md-input v-model="admin.emailAddress"></md-input>
                    <md-button class="md-just-icon md-simple md-danger md-tiny"
                      v-if="tenant.additionalAdmins.length > 0" @click="removeAdmin(index)">
                      <md-icon>delete</md-icon>
                      <md-tooltip md-direction="top">Delete</md-tooltip>
                    </md-button>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>First Name</label>
                    <md-input v-model="admin.firstName"></md-input>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <md-field :class="{ 'md-invalid': errors.length > 0 }">
                    <label>Last Name</label>
                    <md-input v-model="admin.lastName"></md-input>
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click="closeDialog">Close</md-button>
      <md-button class="md-primary" @click="save">Save</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("required", { ...required, message: "This field is required" });

extend("email", { ...email, message: "This is not a valid email address" });

extend("regex", { ...regex, message: "Alpha characters, - and _ only." });

export default {
  name: "CreateEditTenant",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    showDialog() {
      return this.$tenantStore.state.showCreateDialog;
    },
    languages() {
      return this.$tenantStore.state.languages;
    },
    countries() {
      return this.$tenantStore.state.countries;
    },
    currencies() {
      return this.$tenantStore.state.currencies;
    },
    isEditMode() {
      return !!this.tenant.id;
    },
    canChangeCloudConsent() {
      return !this.isEditMode || (this.isEditMode && !this.hasAnyProvision);
    },
    hasAnyProvision() {
      return (
        Array.isArray(this.$tenantStore.state.tenantToEdit.provisions) &&
        this.$tenantStore.state.tenantToEdit.provisions.length > 0
      );
    }
  },
  async created() {
    try {
      const regionsResponse = await this.$featureFlagApi.get(
        "/regions/names"
      );
      this.availableRegions = regionsResponse.data;
    } catch (error) {
      this.$notify({
        message: "Failed to read data necessary to Deploy",
        icon: "running_with_errors",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "danger"
      });
    }
  },
  data() {
    return {
      tenant: {
        id: this.$tenantStore.state.tenantToEdit.id || null,
        tenantCode: this.$tenantStore.state.tenantToEdit.tenantCode || "",
        name: this.$tenantStore.state.tenantToEdit.name || "",
        product: this.$tenantStore.state.tenantToEdit.product || "",
        country: this.$tenantStore.state.tenantToEdit.country || "",
        language: this.$tenantStore.state.tenantToEdit.language || "",
        currency: this.$tenantStore.state.tenantToEdit.currency || "",
        cloudConsent: this.$tenantStore.state.tenantToEdit.cloudConsent ?? true,
        fscCloud: this.$tenantStore.state.tenantToEdit.fscCloud ?? false,
        deploymentEnvironment: this.$tenantStore.state.tenantToEdit.deploymentEnvironment || "",
        domain: this.$tenantStore.state.tenantToEdit.domain || "",
        tenantGroup: this.$tenantStore.state.tenantToEdit.tenantGroup || this.$tenantStore.state.tenantToEdit.tenantCode,
        firstUser: this.$tenantStore.state.tenantToEdit.tenantAdmins
          ? this.$tenantStore.state.tenantToEdit.tenantAdmins[0]
          : {},
        additionalAdmins: this.$tenantStore.state.tenantToEdit.tenantAdmins
          ? this.$tenantStore.state.tenantToEdit.tenantAdmins.slice(
            1,
            this.$tenantStore.state.tenantToEdit.tenantAdmins.length
          )
          : [],
        provisions: this.$tenantStore.state.tenantToEdit.provisions
      },
      availableRegions: [],
      domains: [
        { id: 'claims', description: 'Claims ' },
        { id: 'underwriting', description: 'Underwriting ' },
        { id: 'claims;underwriting', description: 'Claims & Underwriting ' },
        { id: 'kyc', description: 'KYC ' },
      ],
      products: [
        { id: "Claims", description: "Claims (FRISS)" },
        { id: "Underwriting", description: "Underwriting (FRISS)" },
        { id: "UnderwritingCLS", description: "Underwriting (CLS)" },
        { id: "UBO", description: "UBO" },
        { id: "UBOGlobal", description: "UBO Global" }
      ]
    };
  },
  methods: {
    closeDialog() {
      this.$tenantStore.closeCreateEditDialog();
    },
    getRegionHeader(key) {
      return key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    async save() {
      this.$refs.tenantForm.validate().then(async success => {
        if (!success) {
          return;
        }

        this.isEditMode ? await this.update() : await this.create();

        this.closeDialog();
      });
    },
    async create() {
      try {
        const newTenant = {
          id: null,
          tenantCode: this.tenant.tenantCode,
          name: this.tenant.name,
          product: this.tenant.product,
          country: this.tenant.country,
          language: this.tenant.language,
          currency: this.tenant.currency,
          cloudConsent: this.tenant.cloudConsent,
          fscCloud: this.tenant.fscCloud,
          deploymentEnvironment: this.tenant.deploymentEnvironment,
          domain: this.tenant.domain,
          tenantGroup: this.tenant.tenantGroup,
          tenantAdmins: [this.tenant.firstUser, ...this.tenant.additionalAdmins]
        };

        await this.$tenantStore.addTenant(newTenant);

        this.$notify({
          message: "Tenant created successfully!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "success"
        });
      } catch (error) {
        let message = "Failed to create new tenant.";
        if (error.response) {
          message += `: ${error.response.data.errors.join(" ")}`;
        }

        this.$notify({
          message: message,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger"
        });
      }
    },
    async update() {
      try {
        const tenant = {
          id: this.tenant.id,
          tenantCode: this.tenant.tenantCode,
          name: this.tenant.name,
          product: this.tenant.product,
          country: this.tenant.country,
          language: this.tenant.language,
          currency: this.tenant.currency,
          cloudConsent: this.tenant.cloudConsent,
          fscCloud: this.tenant.fscCloud,
          deploymentEnvironment: this.tenant.deploymentEnvironment,
          domain: this.tenant.domain,
          tenantGroup: this.tenant.tenantGroup,
          tenantAdmins: [this.tenant.firstUser, ...this.tenant.additionalAdmins]
        };

        await this.$tenantStore.updateTenant(tenant);

        this.$notify({
          message: "Tenant updated successfully!",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "success"
        });
      } catch (error) {
        let message = "Failed to update tenant.";
        if (error.response) {
          message += `: ${error.response.data.errors.join(" ")}`;
        }

        this.$notify({
          message: message,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "danger"
        });
      }
    },
    addAdmin() {
      this.tenant.additionalAdmins.push({
        emailAddress: "",
        firstName: "",
        lastName: ""
      });
    },
    removeAdmin(index) {
      this.tenant.additionalAdmins.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.md-helper-text {
  padding-right: 25px;
  font-size: 11px !important;
}
</style>
